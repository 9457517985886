<template>
  <div>
    <frequencyDistributionForMicrobe :chartData="chartData" title="기회감염균"></frequencyDistributionForMicrobe>
  </div>
</template>

<script>
import frequencyDistributionForMicrobe from './frequencyDistributionForMicrobe'

export default {
  name: 'OpportunisticInfectiousBacteria', //기회 감염균
  components: { frequencyDistributionForMicrobe },
  data() {
    return {
      chartData : {
        Citrobacter : {},
        Klebsiella  : {},
        Sutterella : {},
        Enterobacter : {},
        Proteus : {},
        Catabacter : {},
        Haemophilus  : {},
        Duodenibacillus : {},
      },
    }

  },
  methods: {
  },
  mounted() {
  },
  created () {
  },
  computed : {
  }

}
</script>

<style scoped>
.vCard {
 border: 1px solid white;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-text-field >>> label {
  font-size: 13px;
}
</style>

