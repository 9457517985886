export default {
    SELECT_METHOD : {
        ANOVA_TWO : "Anova 분석(2개)",
        ANOVA_THREE: "Anova 분석(3개)",
    },
    SEARCH_METHOD_TYPE : {
        ANOVA_TWO: 2,
        ANOVA_THREE: 3,
        ETC: 4,
    },
    CONDITION : {
        G1 : "G1",
        G2 : "G2",
        G3 : "G3"
    }
}