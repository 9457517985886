<template>
  <div>
    <div style="height:50px">
    </div>
    <v-container fluid >
      <v-layout wrap row>
        <v-flex xs12 sm12 md12 class="pb-3">
          <v-card
              class="pa-2 vCard"
              outlined
          >

            <v-row no-gutters>
              <v-col cols="1">
              </v-col>
              <v-col justify="left" align="left" style="margin-left: 30px; border-left : 1px solid #cbcbcb; border-right : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb; border-bottom: 1px solid #cbcbcb">
                <div style="height: 10px"></div>
                <v-chart :options="boxPlotOptions" :initOptions="boxPlotChartSize" />
                <div style="height: 100px"></div>
              </v-col>
              <v-col cols="1">
              </v-col>
            </v-row>
          </v-card>
          <v-card
              class="pa-2 vCard"
              outlined
              v-for="(microbeName, i) in Object.keys(drawData)" :key="i"
          >
            <v-row no-gutters>
              <v-col cols="2">
              </v-col>
              <v-col ref="boxPlot" justify="start" align="center" style="border-left : 1px solid #cbcbcb; border-right : 1px solid #cbcbcb; border-top : 1px solid #cbcbcb; border-bottom: 1px solid #cbcbcb">
                <div style="height: 10px"></div>
                <span style="font-size: 20px; font-weight: bold"> {{microbeName}} </span>
                <v-chart :options="drawData[microbeName]" :initOptions="chartSize" />
<!--
                <v-chart :options="boxPlotData[microbeName]" :initOptions="chartSize" />
-->
              </v-col>
              <v-col cols="2">
              </v-col>
            </v-row>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>
    <input type="hidden" :value="sample" />
    <v-overlay :value="overlayLoading">
      <v-progress-circular
          indeterminate
          size="65"
      ></v-progress-circular>
    </v-overlay>
  </div>

</template>

<script>
import Constants from '../../store/constants'
import {  prepareBoxplotData  } from 'echarts/extension/dataTool' ;
import { mapActions } from 'vuex'

export default {
  name: 'frequencyDistributionForMicrobe',
  props: {
    chartData : Object,
    title : {
      type : String,
      default : ''
    },
    type : {
      type : String,
      default : 'default'
    }
  },
  data() {
    return {
      overlayLoading : false,
      boxPlotOptions : {},
      boxPlotChartSize : { width: 900, height: 500},
      chartSize : { width: 600, height: 400},
      drawData : {...this.chartData},
      constants : Constants,
      chart : {},
      boxPlotData : {},
      CODE_VALUE_IDX : 1,
      CODE_ID_IDX: 2,
      rawDataObj : {
        dataList : [],
        microbeList : []
      },
      codePointDataObj :  {
        dataList : [],
        sampleId : ""
      },
      boxplotPromiseList : []
    }

  },
  methods: {
    ...mapActions(['getByAgeDiversityGenus', 'getByAgeDiversitySpeciesV2']),
    dataProcess (microbeName) {
        return new Promise((resolve, reject) => {
          if(this.type === "default" || !this.checkSpace(microbeName) ) {
            this.getByAgeDiversityGenus(microbeName).then( (ret) => {
              this.drawData[microbeName] = ret.freqDistributionChartData;
              //boxplot Data 처리를 위한
              resolve({
                microbeName : microbeName,
                rawDataList : ret.rawData,
                codeDataList : ret.codeDataList
              });
            }).catch( e =>{
              console.log(e);
              reject(undefined)
            });

          } else {
            this.getByAgeDiversitySpeciesV2(this.chartData[microbeName]).then( (ret) => {
              this.drawData[microbeName] = ret.freqDistributionChartData;
              //boxplot Data 처리를 위한
              resolve({
                microbeName : microbeName,
                rawDataList : ret.rawData,
                codeDataList : ret.codeDataList
              });
            }).catch( e =>{
              console.log(e);
              reject(undefined)
            });
          }

        });

    },
    drawFreqDistributionChart () {
      let boxplotPromiseList = [];
      try {
        this.overlayLoading = true;
        Object.keys(this.chartData).reduce( (acc, microbeName) => {
          boxplotPromiseList.push(this.dataProcess(microbeName));
        }, 0);

        Promise.all(boxplotPromiseList).then(values => {
          this.boxPlot(values);
          this.overlayLoading = false;
        })

      } catch (e) {
        alert("차트 데이터를 가져 오는데 실패 했습니다.")
        console.log(e)
        this.overlayLoading = false;
      }

    },

    addCodePointData(codeDataList = []) {
      try {
        this.codePointDataObj.dataList.push( (codeDataList[0][this.CODE_VALUE_IDX]).toFixed(2) || 0);
        this.codePointDataObj.sampleId = codeDataList[0][this.CODE_ID_IDX] || 0;
      }catch (e) {
        this.codePointDataObj.dataList.push(null);
        console.log(e)
      }
    },
    boxPlot(dataObjList = []) {
      this.rawDataObj.dataList = [];
      this.rawDataObj.microbeList = [];
      this.codePointDataObj.dataList = [];

      let absenceRatioObj = {

      }


      for(let dataObj of dataObjList) {
        let dataList = [];
        let valueZeroCnt = 0;
        let valueTotalCnt = 0;

        this.addCodePointData(dataObj.codeDataList);
        dataObj.rawDataList.reduce( (acc, obj) => {
          if(obj.value === 0) valueZeroCnt++; //return;
          dataList.push(obj.value.toFixed(2));
          valueTotalCnt++;
        }, 0);

        this.rawDataObj.dataList.push(dataList);
        this.rawDataObj.microbeList.push(dataObj.microbeName);
        absenceRatioObj[dataObj.microbeName] = _absenceRatio(valueZeroCnt, valueTotalCnt);
      }

      let data = prepareBoxplotData(this.rawDataObj.dataList);

      console.log("ewrewrewrewrewr")
      console.dir(data.boxData)


      let seriesData = [];
      let microbeIdx = 0;

      data.axisData = [];

      for(let list of data.boxData) {
        seriesData.push( {
          value : list,
          name : this.rawDataObj.microbeList[microbeIdx] || '',
          title : this.rawDataObj.microbeList[microbeIdx] || ''
        }) ;
        data.axisData.push(this.rawDataObj.microbeList[microbeIdx] || '') ;
        microbeIdx++;
      }

      let absenceRatioStr = Object.keys(absenceRatioObj).map(key => `${key}: ${absenceRatioObj[key]}%`).join("\n");

      this.boxPlotOptions = {
        title: [
          {
            text: this.title,
            //subtext: `Absence Ratio: ${str}%`,
            left: 'center',
          },
          {
            text: 'upper: Q3 + 1.5 * IQR \nlower: Q1 - 1.5 * IQR',
            borderColor: '#999',
            borderWidth: 1,
            textStyle: {
              fontFamily : "consolas",
              fontSize: 12
            },
            left: '10%',
            top: '3%'
          },
          {
            text: absenceRatioStr,
            borderColor: '#999',
            borderWidth: 1,
            textStyle: {
              fontFamily : "consolas",
              fontSize: 12
            },
            left: '72%',
            top: '0%'
          }
        ],
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '12%'
        },
        xAxis: {
          type: 'category',
          data: data.axisData,
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: false
          },
          axisLabel: {
            fontSize : 12,
            formatter: '{value}',
            rotate : -20,
            margin : 10
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          //name: 'km/s minus 299,000',
          splitArea: {
            show: true
          },
          logBase : 2
        },
        series: [
          {
            name: 'boxplot',
            type: 'boxplot',
            data: seriesData,
            itemStyle : {
              borderColor: '#000080'
            },
            tooltip: {
              formatter: function (param) {
                return [
                  '<center>' + param.name + '</center>',
                  'upper: ' + param.data.value[5].toFixed(2) || 0,
                  'Q3: ' + param.data.value[4].toFixed(2) || 0,
                  'median: ' + param.data.value[3].toFixed(2) || 0,
                  'Q1: ' + param.data.value[2].toFixed(2) || 0,
                  'lower: ' + param.data.value[1].toFixed(2) || 0
                ].join('<br/>');
              }
            }
          },
          {
            name: 'outlier',
            type: 'scatter',
            data: data.outliers
          },
          {
            name: this.codePointDataObj.sampleId,
            type: 'scatter',
            symbol : 'diamond',
            symbolSize : 15,
            z : 999,
            itemStyle : {
              color : '#00c500'
            },
            tooltip: {
              formatter: function (param) {
                console.dir(param)
                return [
                  '<center>' + param.seriesName + '</center>',
                  `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>` + param.data || 0
                ].join('');
              }
            },
            data: this.codePointDataObj.dataList
          }
        ]
      };

      function _absenceRatio(zeroCnt, totalCnt) {
        try {
          return ((zeroCnt / totalCnt) * 100).toFixed(2);
        }catch (e){
          console.log(e);
        }
        return 0;
      }
    },
    checkSpace (str) {
      if(str.search(/\s/) != -1) {
        return true;
      } else {
        return false;
      }
    },
    chartResize () {
      try {
        let resizeWidth = this.$refs.boxPlot[0].clientWidth;
        if(resizeWidth > 600) {
          resizeWidth = resizeWidth + 200;
        }
        this.boxPlotChartSize.width = resizeWidth;
        console.log("reSizeWidth --> %s", resizeWidth);
      } catch (e) {
        console.log("resize", e);
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.chartResize);
  },
  created () {
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.chartResize);
  },
  computed : {
    sample() {
      this.drawFreqDistributionChart();
      return this.$store.state.sample;
    },
  }

}
</script>

<style scoped>
.vCard {
  border: 1px solid white;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-text-field >>> label {
  font-size: 13px;
}
</style>

